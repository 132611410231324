import React from 'react';
import ReactDOM from 'react-dom';
import { AppProvider, Frame } from '@shopify/polaris';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/index';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AppProvider>
    <Frame>
      <Provider store={store}>
        <App />
      </Provider>
    </Frame>
  </AppProvider>,
);