import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Router from './router/Router';
import { useDispatch, useSelector, Provider } from 'react-redux';
import { Spinner } from '@shopify/polaris';
import { GenerateToken } from './store/storeSlice';
// import { NavMenu } from '@shopify/app-bridge-react';
import { config } from './helper/commonApi';
import Dashboard from './Pages/Dashboard';
import ProductFilter from "./Pages/ProductFilter";
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';
import { NavigationMenu, RoutePropagator } from '@shopify/app-bridge/actions';

export function DefaultLayout() {
    console.log('here');

    const dispatch = useDispatch();
    const app_location = useLocation();
    console.log('app_location........', app_location);
    console.log('app_location host ........', app_location?.state?.config?.host);
    console.log('app_location shop........', app_location?.state?.config?.shop);
    console.log('1 host........', config.host);
    console.log('1 shop........', config.shop);

    if (!config.host) {
        config.host = app_location?.state?.config?.host ? app_location?.state?.config?.host : config.host;
        config.shop = app_location?.state?.config?.shop ? app_location?.state?.config?.shop : config.shop;
    }

    console.log('2 host........', config.host);
    console.log('shop........', config.shop);
    useMemo(async () => {
        await dispatch(GenerateToken()).unwrap();
    }, []);

    const clientStoreData = useSelector(state => state?.clientStoreData);
    const { status, clientInfo } = clientStoreData;

    console.log('status........', status);
    console.log('clientInfo........', clientInfo);

    if (clientInfo && status) {
        console.log('enter');
        console.log('window.location.hostname...........', window.location.hostname);
        if (window.location.hostname === "localhost") {
            console.log('ifff');
            return <Router />;
        } else {
            console.log('else');
            console.log('config............', config);
            const navigationLink = [
                {
                    label: 'Dashboard',
                    destination: '/'
                }
            ];

            return (
                <>
                    <AppProvider i18n={enTranslations}>
                        {status && process.env?.MODE !== 'local' ? <Provider config={config}>
                            <NavigationMenu
                                navigationLinks={navigationLink}
                                matcher={(link, location) => link.destination === location.pathname} />
                            <Frame>
                                <RoutePropagator location={app_location} />
                                <Outlet />
                            </Frame>
                        </Provider> : status && <Outlet />
                        }
                    </AppProvider>
                    {/* <Provider config={config}>
                        <NavMenu>
                            <a href="/" rel="home">home</a>
                            <a href="/product">Product</a>
                            <a href="/Customer">Customer</a>
                            <a href="/setting">Settings</a>
                            <a href="/pricing_plan">Pricing plan</a>
                            <a href="/feature_req">Request a Feature</a>
                        </NavMenu>
                        <Router />
                    </Provider>
                    <Outlet /> */}
                </>
            );
        }
    } else if (!status) {
        console.log('else iff');
        return <><div className='main-spinner'><Spinner accessibilityLabel="Spinner example" size="large" /></div></>;
    }
}

export const routes = [
    { path: '/', component: <Dashboard /> },
    { path: '/product', component: <ProductFilter /> }
];