import '@shopify/polaris/build/esm/styles.css';
import './App.css';
import React from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import { DefaultLayout, routes } from './DefaultLayout';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<DefaultLayout />} >
          {routes.map((route, i) => (<Route exact={true} path={route.path}
          key={i} element={route.component} />))}
        <Route element={<Navigate to='/' replace />} /></Route>
      </Routes>
    </BrowserRouter >
  );
}

export default App;
